
.swiper {
    width: 100%;
    height: 100%;
}
  
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.swiper-button-prev {
    color: white;
}


.swiper-button-next {
    color: white;
}

.swiper-pagination-bullet-active {
    background-color: rgba(255, 255, 255, 0.661);
}