@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fbfbfb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header {
  width: 95%;
}

.default-bg-color {
  background-color: #434343;
  color: white;
}

.default-color {
  color: rgb(30 64 175);
}


.default-font {
  font-family: 'Oswald', sans-serif;
  /* font-family: 'poppins', sans-serif; */
  /* font-family: "Montserrat", sans-serif; */

}

.desc-font {
  font-family: 'poppins', sans-serif;
}

.swiper-button-prev, .swiper-button-next { 
  color: #767676 !important;
}

.swiper-slide {
  opacity: 0!important;
}

.swiper-slide-active {
  opacity: 1!important;
}

.active {
  color: rgb(30 64 175);;
  text-decoration: underline;
}

.highligth:hover {
  color: rgb(30 64 175);;
  cursor: pointer;
}

.logo {
  float: left;
  max-width: 100%;
  position: relative;
  z-index: 999;
  /* background: #b50526; */
  padding: 25px 70px;
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -webkit-transform: skew(-30deg);
  padding-left: 0px;
  width: 35rem;
  left: -100px;
}

.reversify {
  -moz-transform: skew(30deg);
  -ms-transform: skew(30deg);
  -webkit-transform: skew(30deg);

}

/* .logo::before {
  background-color: #e2002b;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  left: -999999px;
  top: 0;
  width: 999999px;
} */

.head_inner {
  width: 100%;
  float: left;
  top: 0;
}

.ccenter {
  /* width: 1170px;
  margin: 0 auto; */
}

.cheader {
  width: 100%;
    float: left;
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out;
    position: absolute;
}

.menu-bar {
  float: left;
  /* max-width: 100%; */
  position: relative;
  z-index: 996;
  height: 5rem;
  top: 2rem;
  /* background: #b50526; */
  padding: 25px 70px;
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -webkit-transform: skew(-30deg);
  padding-left: 0px;
  width: 45rem;
  left: -130px;
}

.login-bar {
  float: left;
  /* max-width: 100%; */
  position: relative;
  z-index: 994;
  height: 2rem;
  top: 4.5rem;
  /* background: #b50526; */
  padding: 25px 70px;
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -webkit-transform: skew(-30deg);
  padding-left: 0px;
  width: 7rem;
  left: -150px;
}


.login-bar::after {
  width: 0;
  height: 0;
  border-top: 15px solid #000000;
  border-left: 30px solid transparent;
  content: "";
  position: absolute;
  left: -29px;
  top: 35px;
}

textarea:focus, input:focus{
  outline: none;
}

.h-30r {
  height: 15rem;
}
